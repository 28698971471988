<template>
  <div class="page-home">
    <div class="index_title">{{ result.title }}</div>
    <div class="business-title">请选择您要办理的业务：</div>
    <ul class="business-box">
      <li v-for="(item, index) in businessdata" :key="index">
        <div class="business-list" :class="{active:shows== item.id}" @click="onSelect(item.id)">{{ item.business }}</div>
      </li>
    </ul>
    <!--    <van-overlay :show="show" @click="show = false">-->
    <!--      <div class="wrapper" @click.stop>-->
    <!--        <div class="block">-->
    <!--          <div class="dialog_ico">-->
    <!--            <img src="../../assets/warning.png">-->
    <!--          </div>-->
    <!--          <div class="dialog_content">不含机动车解押、机动车注销、<br> 面签业务</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </van-overlay>-->

    <!--    <div class="hall_btn">-->
    <!--      <van-button type="primary" block color="#106FFF" class="btn" :disabled="disable" @click.native="onSubmit">确定</van-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import Vue from 'vue'
import { getBusiness } from '@/api/data'
import { getBranch } from '@/utils/auth'
import { mapGetters } from 'vuex'
// import Cookies from 'js-cookie'
import { Button, Empty, Col, Row, Overlay, Toast } from 'vant'
Vue.use(Toast)

export default {
  inject: ['routerRefresh'], // 在子组件中注入在父组件中创建的属性
  name: 'HomeIndex',
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Col.name]: Col,
    [Row.name]: Row,
    [Overlay.name]: Overlay
  },
  data() {
    return {
      logo: require('../../assets/logo.png'),
      icosrc: require('../../assets/warning.png'),
      statusdata: '',
      status: 1,
      timer: '',
      listTimer: '',
      disable: true,
      value: 0,
      result: '',
      sysset_id: '',
      branch: getBranch(),
      shows: '',
      businessdata: '',
      show: false,
      select: ''
    }
  },
  computed: {
    avatar() {
      return this.userInfo ? this.userInfo.avatar : this.logo
    },
    introduction() {
      return this.userInfo ? this.userInfo.introduction : '南京蓝牛软件有限公司'
    },
    ...mapGetters([
      'userInfo',
      'branchInfo'
    ])
  },
  mounted() {
    // clearInterval(this.timer)
  },
  created() {
    // var url = window.location.href.split('#')[0]
    // var url = window.location.href.replace('#', '')
    // Cookies.set('homeUrl', url)
    // console.log(url)
    // console.log(window.location.href.replace('home', 'get'))
    if (this.$route.query.sysset_id) {
      this.disable = false
      this.sysset_id = this.$route.query.sysset_id
      this.getnetworkInfo(this.sysset_id)
    } else {
      if (this.branch) {
        this.disable = false
        this.result = JSON.parse(this.branch)
        this.sysset_id = this.result.id
      } else {
        Toast({
          type: 'fail',
          duration: '2000',
          message: '地址不正确,请重新扫码',
          forbidClick: true
        })
      }
    }
    this.getBusinessInfo()
  },
  methods: {
    onSelect(type) {
      var self = this
      self.shows = type
      self.select = type

      self.$router.push({ name: 'get.index', query: { sysset_id: self.sysset_id, business_id: type }})
      self.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程

      // self.show = true
      // setTimeout(function() {
      // if (self.userInfo.uid) {
      //   console.log('11111')
      //   self.$router.push({ name: 'get.index', query: { sysset_id: self.sysset_id, business_id: type }})
      //   self.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
      // } else {
      //   console.log('22222')
      //   self.$router.push({ name: 'user.login', query: { 'redirect': 'home', sysset_id: self.sysset_id }})
      //   self.routerRefresh()
      // }
      // }, 100)
    },
    getnetworkInfo(values) {
      this.loading = true
      this.$store.dispatch('app/getRule', values).then(res => {
        this.result = res
        this.sysset_id = this.result.id
        this.loading = false
      })
    },
    getBusinessInfo(values) {
      getBusiness({ 'sysset_id': this.sysset_id }).then((response) => {
        if (response.data) {
          this.businessdata = response.data
        } else {
          Toast({
            type: 'fail',
            message: '该网点暂无业务',
            forbidClick: true
          })
        }
      })
    },
    onSubmit() {
      var self = this
      if (self.userInfo.token) {
        self.show = false
        self.$router.push({ name: 'get.index', query: { sysset_id: this.sysset_id, business_id: self.select }})
        self.routerRefresh() // 调用app.vue里面的routerRefresh()方法，完成摧毁和重建过程
      } else {
        self.show = false
        self.$router.push({ name: 'user.login', query: { 'redirect': 'home' }})
      }
    },
    beforeDestroy() {
      clearInterval(this.timer)
    }
  }
}
</script>

<style lang="less" scoped>
  .index_title{width: 100%;float: left;height: 50px;line-height: 50px; text-align: center;font-size: 16px; color: #26272C;border-bottom: 1px #F7F7F7 solid;font-family: 'Microsoft YaHei';}
  .page-home{width: 100%;float: left; background: #ffffff; overflow: scroll;}
  .business-title{width: 90%;margin: 30px 5% 10px 5%; float: left;height: 40px;line-height: 40px;text-align: left;font-size: 18px;font-weight: bold;}
  .business-box{width: 90%;margin: 10px 5% 30px 5%;float: left;}
  .business-list{width: 100%;height: 60px;line-height: 60px;text-align: center;color: #8993A8;font-size: 16px; float: left;margin: 10px 0;border-radius: 10px; border: 2px solid #E5E9EF;}
  .active{background: #F0F3F5;color: #101112;}
  .van-dialog__content img{width: 100%;}

  /deep/ .van-dialog__footer{float: left;width: 100%;}
  .wrapper {display: flex;align-items: center;justify-content: center; height: 100%;}
  .block {width: 80%; height: 200px; float: left;  background-color: #fff; border-radius: 10px;}
  .dialog_ico{width: 20%;float: left; margin: 20px 40%;}
  .dialog_ico img{width: 100%}
  .dialog_content{width: 100%;float: left;margin: 0px 0px 20px 0px; text-align: center;font-size: 16px;color: #8993A8; line-height: 30px;}

  .hall_btn{whidt: 90%;margin: 10px 5% 10px 5%;}
</style>
